<template>
  <Home />
</template>

<script>
import Home from '../components/Home.vue'

export default {
  name: 'HomeView',
  components: {
    Home,
  },
}
</script>

<style scoped>

</style>